import { Link, StaticQuery, graphql } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => (
    <StaticQuery
    query={graphql`
        query FooterMeta {
            site {
                siteMetadata {
                    title
                    siteUrl
                    author
                    social {
                        fbShare
                        twitterShare
                        linkedinShare
                    }
                }
            }
        }
    `}
    render={data => (
        <>
        <footer>
            <Container>
                <Row>
                    <Col xs='12' sm='12' md='12' lg='12'>
                        <p>
                            <Link to='/cities/'>Cities</Link> 
                            <Link to='/collaborators/'>Collaborators</Link> 
                            <Link to='/co-funders/'>Co-Funders</Link> 
                            <Link to='/news/'>News</Link>
                        </p>
                        <div className='spacer20'></div>
                        <div className='social-icons'>
                            <span><strong>SHARE</strong></span>
                            <a data-href={data.site.siteMetadata.siteUrl} data-layout='button' href={data.site.siteMetadata.social.fbShare} target='_blank' rel='noreferrer'><FontAwesomeIcon aria-label='Facebook' className='icon' icon={['fab','facebook']} /></a>
                            <a href={data.site.siteMetadata.social.linkedinShare} target='_blank' rel='noreferrer'><FontAwesomeIcon aria-label='LinkedIn' className='icon' icon={['fab','linkedin']} /></a>
                            <a href={`${data.site.siteMetadata.social.twitterShare}${data.site.siteMetadata.siteUrl}`} target='_blank' rel='noreferrer'><FontAwesomeIcon aria-label='Twitter' className='icon' icon={['fab','twitter']} /></a>
                        </div>
                        <div className='spacer20'></div>
                        <p>
                        Copyright &copy; {new Date().getFullYear()}{' '}
                        {data.site.siteMetadata.title}. All rights reserved.{' '}
                        <a
                            href="#"
                            className="cookie-settings-link"
                            onClick={(e) => {
                            e.preventDefault();
                            window.OneTrust && window.OneTrust.ToggleInfoDisplay();
                            }}
                        >
                            Cookie Preferences
                        </a>
                        </p>
                        <div className='icons'></div>
                    </Col>
                </Row>
            </Container>
        </footer>
        </>
    )}
    />
)

export default Footer
