import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

import Header from './header'
import Footer from './footer'

import '../../node_modules/bootstrap/scss/bootstrap.scss'
import './scss/global.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faSearch)

const Layout = ({ children, page }) => (
    <StaticQuery
    query={graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    author
                    google {
                        googleAnalyticsId
                    }
                }
            }
        }
    `}
    render={data => (
        <>
            <div id='fb-root'></div>
            <script async defer src='https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=167680863312844&autoLogAppEvents=1' nonce='um4uXyP8'></script>
            <div className='wrapper'>
                <Header pageName={page} />
                <main>{children}</main>
                <Footer />
            </div>
        </>
    )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.string
}

export default Layout
